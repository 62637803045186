<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <i class="icon-info"></i> Informações do Produto
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group label="Produto" label-for="titulo">
                  <b-form-input
                    id="titulo"
                    type="text"
                    :value="produto.titulo"
                    placeholder="Produto"
                    readonly
                  />
                </b-form-group>
                <b-form-group label="Descricao" label-for="descricao">
                  <b-form-input
                    id="descricao"
                    type="text"
                    :value="produto.descricao"
                    placeholder="Descricao"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Produto from "../../../services/produto";

export default {
  name: "Produto",
  data: () => ({
    produto: new Object(),
    codigo: null
  }),
  computed: {},
  beforeMount() {
    this.codigo = this.$route.params.codigo;
    this.loadProduct(this.codigo);
  },
  methods: {
    loadProduct(codigo) {
      this.produto = new Object();
      Produto.getByCodigo(codigo)
        .then(response => {
          this.produto = response.data.data;
        })
        .catch(e => {
          this.$helper.showErrorResponse(e);
        });
    }
  }
};
</script>
